import FormsInputMaterial from "@olmokit/core/forms/input/material";
import FormsSelectMaterial from "@olmokit/core/forms/select/material";
import "@olmokit/core/forms/checkbox";
import FormsTextareaMaterial from "@olmokit/core/forms/textarea/material";
import Olmoforms from "@olmokit/core/olmoforms/feedback";
import Dependency from "@olmokit/core/olmoforms/dependency";
import FormUploadFile from "@olmokit/core/forms/file/olmominimal";
import { $, on } from "@olmokit/dom";
import { countryEndpoint, hideProject, showProject } from "components/Form";
import { dataLayerFillingForm, dataLayerForm } from "components/Datalayer";
import { salesforceCommunication } from "components/Form";
import "./Collaboration.scss";

/**
 * Component: FormProductCart
 */
export function FormCollaboration() {
  console.log("FormCollaboration mounted.");

  dataLayerFillingForm('#contactcollaboration .of:el', 'Form Collaboration')

  const $container = $('.FormCollaboration: .of:country');
  const $country = $('select', $container);

  on($country, 'change', () => {   
    countryEndpoint(".FormCollaboration:", $country.value);
  });    

  const $jobcontainer = $('.FormCollaboration: .of:job');
  const $jobfield = $('select', $jobcontainer);
  const $projcontainer = $('.FormCollaboration: .of:project');
  const $projfield = $('select', $projcontainer);
  const $mqcontainer = $('.FormCollaboration: .of:projectmq');
  const $yearcontainer = $('.FormCollaboration: .of:projectyear');

  on($jobfield, 'change', () => {   
    if ($jobfield.value == 'Press Stampa') {
      hideProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
    } else {
      $projcontainer.style.display = 'block';
      showProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
    }
  });
  on($projfield, 'change', () => {
    showProject($projcontainer, $projfield, $mqcontainer, $yearcontainer);
  })

  const olmoforms = Olmoforms(".FormCollaboration:", {
    sent: (formData) => {
      dataLayerForm("form sent", formData, "Form Collaboration", $('#of-3-usertype').value, $('#of-3-requesttype').value);
      salesforceCommunication(formData, "Form Collaboration");
    }, 
    failed: (instance) => dataLayerForm("form_error", '', "Form Collaboration", $('#of-3-usertype').value, $('#of-3-requesttype').value)
  });

  FormsInputMaterial();
  Dependency(".FormCollaboration:");
  FormsSelectMaterial();
  FormUploadFile();  
  FormsTextareaMaterial();

  return {
    destroy() {
      olmoforms.destroy();
    },
  };


}
