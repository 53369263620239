export { FormProductCart as FormProductCart } from "components/Form/ProductCart";
export { FormType as FormType } from "components/Form/Type";
export { FormOther as FormOther } from "components/Form/Other";
export { FormNewsletter as FormNewsletter } from "components/Form/Newsletter";
export { FormCollaboration as FormCollaboration } from "components/Form/Collaboration";
export { FormMaterial as FormMaterial } from "components/Form/Material";
export { FormDealer as FormDealer } from "components/Form/Dealer";
export { FormProduct as FormProduct } from "components/Form/Product";
import { Ajax } from "@olmokit/core/ajax";
import { globalConf } from "@olmokit/core/data";
import { $ } from "@olmokit/dom";
import { dataLayerForm, dataLayerNewsForm } from "components/Datalayer";
import axios from 'axios';
import "./index.scss";

/**
 * Component: Form
 */
export function Form() {
  console.log("Form mounted.");  
}

export function countryEndpoint(form, iso){
  const $form = $(form);
  const $containera = $('.of:a', $form);
  const $a = $('input', $containera);      
  Ajax(globalConf.cmsApiUrl+"/"+globalConf.locale+"/service-endpint/country/"+iso, 
  {
    method: "POST",
    data: {
      field: "name_txt_general",
    },
  }).then(({ data }) => {
    $a.value = data.email;
  });    
}

export function showProject(projc, projf, mq, year) {
  projf.setAttribute('required', 'required');
  if (projf.value == 'Nessun Progetto') {
    mq.style.display = 'none';
    year.style.display = 'none';
  } else {
    mq.style.display = 'block';
    year.style.display = 'block';
  }
}
export function hideProject(projc, projf, mq, year) {
  projc.style.display = 'none';
  projf.removeAttribute('required');
  mq.style.display = 'none';
  year.style.display = 'none';
  
}

export function salesforceCommunication(formData, formType) {

  const utmSource = localStorage.getItem('utm_source');

  formData['utmsource'] = utmSource;

  axios.post(globalConf.cmsApiUrl+'/'+globalConf.locale+'/salesforce/access', formData)
  .then(() => {
    console.log('salesforce done');
  })
  .catch((error) => {
    if(formType == "Newsletter"){
      dataLayerNewsForm("salesforce_error", formData);
    }else{
      dataLayerForm("salesforce_error", formData, formType, $('#of-3-usertype').value, $('#of-3-requesttype').value);
    }
    console.log(error);
  });

}